import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import Prismic from 'prismic-javascript';
import PrismicDOM from "prismic-dom";

import Layout from '../components/layout';
import ContactForm from '../components/ContactForm';
import SEO from "../components/seo"

const prismicURL = 'https://blackway.prismic.io/api/v2';

const Contact = () => {
	const [ schedule, setSchedule ] = useState(null);

	useEffect(() => {
		Prismic.getApi(prismicURL).then((api) => api.query(
			Prismic.Predicates.at('document.type', 'horaires')
		))
			.then(response => {
				setSchedule(PrismicDOM.RichText.asHtml(response.results[0].data.horaires));
			});
	}, []);

	return (
		<Layout>
			<SEO
				title="Contact"
				description="Retrouvez Black Way Motorcycles au Route de Pré-Marais 22, 1233 Bernex, Genève, Suisse."
				lang="fr"
				url="/contact"
			/>

			<div className="box-container first">
				<div className="content-block">
					<div className="content-block-bg" />
					<h2>Contact</h2>

					<Row type="flex" justify="space-around" style={{ marginBottom: 60 }} gutter={16}>
						<Col xs={24} md={12} xl={8}>
							<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
								<h3>RETROUVEZ-NOUS</h3>
								<p><strong>BLACK WAY SA</strong></p>
								<p style={{ marginBottom: 0 }}><a href="https://www.google.com/maps/place/Route+de+Pr%C3%A9-Marais+22,+1233+Bernex/data=!4m2!3m1!1s0x478c7c9787ca4161:0x72eb22515cb1d476?sa=X&ved=2ahUKEwjV1YiO5MXiAhUGaVAKHZiqCTsQ8gEwAHoECAoQAQ" target="_blank" rel="noopener nofollow">Route de Pré-Marais 22</a></p>
								<p><a href="https://www.google.com/maps/place/Route+de+Pr%C3%A9-Marais+22,+1233+Bernex/data=!4m2!3m1!1s0x478c7c9787ca4161:0x72eb22515cb1d476?sa=X&ved=2ahUKEwjV1YiO5MXiAhUGaVAKHZiqCTsQ8gEwAHoECAoQAQ" target="_blank" rel="noopener nofollow">1233 Bernex, Genève, Suisse</a></p>
								<p><a href="mailto:contact@black-way.com">info@black-way.com</a></p>
								<p style={{ marginBottom: 0 }}><a href="tel:+41227577021">+41 22 757 70 21</a></p>
							</Card>
						</Col>

						<Col xs={24} md={12} xl={8}>
							<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
								<h3>HORAIRES</h3>
								<div className="schedules" dangerouslySetInnerHTML={{ __html: schedule }} />
							</Card>
						</Col>
					</Row>

					<ContactForm />
				</div>
			</div>
		</Layout>
	);
}

export default Contact;
